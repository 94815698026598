import React from "react";

const Footer = () => {

  return (
    <>
      <footer>

        <div className="container py-4">
        <div className="container bt-1 mb-5"></div>
          <div className="row align-items-center">
            <div className="col-md-2 mb-md-0 mb-5">
            <div className="d-flex flex-md-row flex-column align-items-md-center gap-4 text-center text-md-start">
              <span><img src="assets/logo.svg" className="light-logo" alt="" /></span>
            </div>
            </div>
            <div className="col-md-4 order-md-0 order-1 text-md-start text-center"><p className="fs-14 mb-0">Copyright 2024 Hisoka Finance. All righits reserved.</p></div>
            <div className="col-md-6 mb-md-0 mb-4">
            <div className="d-flex flex-md-row flex-column justify-content-md-end justify-content-center align-items-center gap-4">
              
              <div className="d-flex justify-content-md-end align-items-center gap-4">
                <a href="https://hisoka-finance.gitbook.io/hisoka-finance/" target={"_blank"} className="footer-ic" rel="noreferrer"><span><img src="assets/gitbook.svg" alt="" /></span></a>
                <a href="https://x.com/HisokaFinance" target={"_blank"} className="footer-ic" rel="noreferrer"><span><img src="assets/twitter.svg" alt="" /></span></a>
                <a href="javascript:void(0)" target={"_blank"} className="footer-ic" rel="noreferrer"><span><img src="assets/medium.svg" alt="" /></span></a>
                <a href="https://discord.gg/HisokaFinance" target={"_blank"} className="primary-btn gap-2" rel="noreferrer">
                Join Discord <span><img src="assets/discord.svg" alt="" /></span>
              </a>
              </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container ">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 order-md-0 order-1 text-md-start text-center"><p className="fs-14 mb-0">Copyright 2024 Hisoka Finance. All righits reserved.</p></div>
            <div className="col-md-6 mb-md-0 mb-4">
            <div className="d-flex flex-md-row flex-column justify-content-md-end justify-content-center align-items-center gap-4">
              <div className="d-flex gap-4">
              <a href="https://x.com/HisokaFinance" target="_blank" rel="noopener noreferrer" className="footer-link">Twitter</a>
              <a href="https://discord.gg/HisokaFinance" target="_blank" rel="noopener noreferrer" className="footer-link">Discord</a>
              <a href="https://hisoka-finance.gitbook.io/hisoka-finance/" target="_blank" rel="noopener noreferrer" className="footer-link">Docs</a>
              </div>
              <div className="d-flex gap-4">
              <a href=" https://hisoka-finance.gitbook.io/hisoka-finance/security/privacy-policy" target="_blank" rel="noopener noreferrer" className="footer-link">Privacy Policy</a>
              <a href="https://hisoka-finance.gitbook.io/hisoka-finance/security/legal-disclaimer" target="_blank" rel="noopener noreferrer" className="footer-link">Legal Disclaimer </a>
              </div>
              </div>
            </div>
          </div>
        </div> */}
      </footer>

    </>
  );
};

export default Footer;
