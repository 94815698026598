import React, { useEffect} from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import Footer from '../Components/Footer';

const Home = () => {
useEffect(function () {
Aos.init({ disable: 'mobile' });
}, []);

   
// const location = useLocation();

// useEffect(() => {
// window.scrollTo(0, 0);
// }, [location]);

return (
<>
  {/* Banner */}
  <div className="top-banner">
  <section className="banner" id="home">
    <div className="container py-lg-5">
      <div className="row">
        <div className="col-md-6 mb-md-0 mb-">
          <h1 className="" data-aos="fade-right" data-aos-duration="800">
            Bringing liquidity to
            <img src="assets/Kakarot_Logotype_white.png" className='ps-2 kak-logo' alt="" />
          </h1>
          <p className="pt-4" data-aos="fade-right" data-aos-duration="1200">
            Hisoka is the first ve(3,3) DEX and liquidity marketplace on Starknet L3.
          </p>
          <div className="pt-md-4" data-aos="fade-right" data-aos-duration="1500">
            <div className="d-inline-flex flex-md-row flex-column gap-3 mb-lg-5 mb-4">
              <a href="http://app.hisoka.finance/" target={"_blank"} rel="noreferrer" className="px-5 primary-btn">
                Open App
              </a>
              <a href="javascript:void(0)" rel="noreferrer" className="px-5 primary-btn-outline">
                Learn More
              </a>
            </div>

          </div>
        </div>
        <div className="col-md-6 ps-lg-5 bg-blend" data-aos="fade-left" data-aos-duration="2000">
          <img src="assets/hisoka.png" className="w-100" alt="" />
        </div>
      </div>
    </div>


  </section>


  <section className="feature-bg ">
    <div className="container bt-1"></div>

    <div className="container py-5">
      <div className="row align-items-center">
        <div className="col-md-6 mb-md-0 mb-4" data-aos="fade-right" data-aos-duration="1800">
          <h2 className='mb-4'><b>ve (3,3)</b> <br /> Incentives + <br className='d-md-block d-none' /> Liquidity
            Flywheel</h2>
          <a href="http://app.hisoka.finance/" target={"_blank"} rel="noreferrer" className='primary-btn px-lg-5'>Open
            App</a>
        </div>
        <div className="col-md-6" data-aos="fade-left" data-aos-duration="1800">
          <p className='mb-md-4'>Hisoka’s custom implementation takes a unique approach to pairing
            incentive multipliers with liquidity concentration to achieve next-level capital efficiency.
            This disruptive method produces the lowest slippage currently feasible in DeFi.</p>
        </div>
      </div>
    </div>
    <div className="container bt-1"></div>

   <div className='bg-2'>
   <div className="container py-5">
      <div className="row align-items-center">
        <div className="col-md-9 mb-md-0 mb-4" data-aos="fade-right" data-aos-duration="1800">
          <h2 className='mb-4'>Swap your tokens with low slippage</h2>
          <p className='mb-md-4'>Hisoka is the first ve(3,3) dex and liquidity marketplace on Starknet L3.</p>
        </div>
        <div className="col-md-3 col-6 mx-auto" data-aos="fade-left" data-aos-duration="1800">
          <img src="assets/H.png" className='w-100' alt="" />
        </div>
      </div>
    </div>


    <div className="container" id="features">
      <div className="row py-4 mb-lg-5">
        <div className="col-md-6 mb-md-0 mb-4">
          <div className="card-clr h-100" data-aos="fade-left" data-aos-duration="1600">
            <div className="h-100">
              <div className='card-detail'>
              <div><img src="assets/ic-1.svg" alt="" /></div>
                <h4 className="my-4">Vote</h4>
                <p>1 - Lock veHSK to create veHSK. <br />
                   2 - Use veHSK to vote for your favorite pools. <br />
                   3 - Earn weekly rewards.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-md-0 mb-4">
          <div className="card-clr h-100" data-aos="fade-right" data-aos-duration="1600">
            <div className="h-100">
              <div className='card-detail'>
                <div><img src="assets/ic-2.svg" alt="" /></div>
                <h4 className="my-4">LP Stake</h4>
                <p>1 - Provide liquidity for your desired pools. <br />
                   2 - Earn weekly rewards in HSK.</p>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
   </div>

    <div className="container">
      <div className="row align-items-center py-4 mb-lg-5">
        <div className="col-md-6 mb-md-0 mb-4" data-aos="fade-right" data-aos-duration="1800">
          <h3 className='mb-4'>Novel ve(3,3)Tokenomics</h3>
         <p>Inspired by Andre Cronje’s Solidly, veHSK takes a novel 
          approach on the voting-escrow model. Hisoka has an exponential decay 
          programmed within the core, ensuring a sustainable token model for years 
          to come. The HSK model rewards long-term supporters, and aligns 
          stakeholders interests by incentivizing fee generation.</p>
        </div>
        <div className="col-md-6" data-aos="fade-left" data-aos-duration="1800">
         <img src="assets/g1.png" className='w-100' alt="" />
        </div>
      </div>

      <div className="row align-items-center py-4 mb-lg-5">
        <div className="col-md-6 mb-md-0 mb-4" data-aos="fade-right" data-aos-duration="1800">
          <h3 className='mb-4'>Community-Owned Protocol</h3>
         <p>Hisoka  has a decentralized Token Generation Event (TGE) 
          that welcomes regular users within the Hisoka  ecosystem, 
          and supports protocols looking to build liquidity.</p>
        </div>
        <div className="col-md-6" data-aos="fade-left" data-aos-duration="1800">
         <img src="assets/g2.png" className='w-100' alt="" />
        </div>
      </div>

      <div className="row align-items-center py-4">
        <div className="col-md-6 mb-md-0 mb-4" data-aos="fade-right" data-aos-duration="1800">
          <h3 className='mb-4'>Low Fee Hybrid AMM</h3>
         <p>With default fees ranging from as low as 0.02% for correlated 
          pools, to 0.25% for volatile pools, Hisoka  gives traders 
          the benefit of low fee swaps, and negligible slippage on closely correlated assets.</p>
        </div>
        <div className="col-md-6" data-aos="fade-left" data-aos-duration="1800">
         <img src="assets/g3.png" className='w-100' alt="" />
        </div>
      </div>
    </div>


    {/* <div className="container pt-5 pool">
      <div className="row align-items-md-center">
        <div className="col-md-12">
          <h2 className="mb-4 inter" data-aos="fade-up" data-aos-duration="1600">Explore Liquidity Pools</h2>
        </div>
        <div className="col-md-6 pe-lg-5 mb-md-0 mb-4">
          <div className="pe-lg-5">



            <div className="nav flex-column gap-3 nav-pills" data-aos="fade-left" data-aos-duration="2000"
              id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                aria-selected="true">
                <div className="bg-11">
                  <div className="bg-12">
                    <div className="bg-13">
                      <h4 className='inter'>Fees</h4>
                      <p className='mb-0'>
                        Hisoka operates with a unique approach to trading fees. When you
                        engage in a transaction, the fees are preserved in the same tokens you used for the exchange.
                      </p>
                    </div>
                  </div>
                </div>
              </button>
              <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile"
                aria-selected="false">
                <div className="bg-11">
                  <div className="bg-12">
                    <div className="bg-13">
                      <h4 className='inter'>Stable Pools</h4>
                      <p className='mb-0'>
                        Stable pools are specifically designed for assets that demonstrate low volatility.
                      </p>
                    </div>
                  </div>
                </div>
              </button>
              <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages"
                aria-selected="false">
                <div className="bg-11">
                  <div className="bg-12">
                    <div className="bg-13">
                      <h4 className='inter'>Variable Pools</h4>
                      <p className='mb-0'>
                        Variable pools focus on assets that experience significant price fluctuations.
                      </p>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 pool pb-lg-5" data-aos="fade-right" data-aos-duration="1800">
          <img src="assets/pool.png" className='w-100 mx-350' alt="" />
          <div className="tab-content mb-md-4" id="v-pills-tabContent">
            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
              aria-labelledby="v-pills-home-tab">
              <p className=''>Hisoka offers custom configurable pools to accommodate all pair types: Stable or Volatile.</p>
            </div>
            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
              <p className=''>Discover the freedom to tailor your trading experience with Hisoka's innovative stable pools, 
              designed specifically for assets with similar price ranges. Enjoy the benefits of customized price curve, 
              reduced slippage, optimized pricing, and a smoother transaction process that caters to your needs.</p>
            </div>
            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
              <p className=''>Explore the dynamic world of volatile pools, designed to accommodate asset pairs with free-floating prices. 
              Hisoka empowers you to take advantage of these ever-changing market conditions, offering unparalleled flexibility 
              and endless trading possibilities.</p>
            </div>
          </div>
          <a href="https://hisoka-finance.gitbook.io/hisoka-finance/" target={'_blank'} className='primary-btn px-lg-5'><span
              className="px-lg-3">Docs</span></a>

        </div>

      </div>
    </div> */}
  </section>


  <Footer />




</div>
</>
);
};

export default Home;
